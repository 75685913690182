<template>
  <div className="password-container">
    <a-form name="basic" ref="formRef" :model="formState" :labelCol="{ span: 7 }" :wrapperCol="{ span: 13 }" @finish="onFinish" autoComplete="off">
      <a-form-item label="Current Password" name="oldPassword" :rules="[ { required: true, message: 'Current password cannot be empty' } ]">
        <a-input-password placeholder="Please enter your current password" v-model:value="formState.oldPassword" />
      </a-form-item>
      <a-form-item label="New Password" name="newPassword" :rules="rules.newPassword">
        <a-input-password placeholder="Please enter a new password" v-model:value="formState.newPassword" />
      </a-form-item>
      <a-form-item label="Confirm Password" name="confirmPassword" :rules="rules.confirmPassword">
        <a-input-password placeholder="Please re-enter your password" v-model:value="formState.confirmPassword" />
      </a-form-item>
      <a-form-item :wrapperCol="{ offset: 7, span: 11, }">
        <a-button type="primary" htmlType="submit">
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { reactive, ref } from '@vue/reactivity';
import { mapState, useStore } from 'vuex';
import { editUserInfo } from "@/api/user";
import { useRouter } from 'vue-router';
export default {
  setup () {
    const store = useStore();
    const router = useRouter();
    const formRef = ref();
    const formState = reactive({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    })
    let validatePass = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Please enter your password');
      } else {
        if (formState.confirmPassword !== '') {
          formRef.value.validateFields('confirmPassword');
        }

        return Promise.resolve();
      }
    };

    let validatePass2 = async (_rule, value) => {
      if (value === '') {
        return Promise.reject('Confirm that the password is not empty');
      } else if (value !== formState.newPassword) {
        return Promise.reject("The password filled in twice is not the same");
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      newPassword: [{
        required: true,
        validator: validatePass,
        trigger: 'change',
      }],
      confirmPassword: [{
        required: true,
        validator: validatePass2,
        trigger: 'change',
      }]
    };
    const onFinish = async (values) => {
      try {
        const { data } = await editUserInfo(values);
        store.commit("SETUSERINFO", data);
        store.commit("SET_TOKEN", data.token);
        message.success("Modified successfully");
        formRef.value.resetFields();
        router.push('/home')
        
      } catch (error) {
      }
    }
    return {
      formState,
      formRef,
      rules,
      onFinish
    }
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    
  }
}
</script>

<style lang="less" scoped>
.password-container {
  padding-top: 24px;
  width: 520px;
  margin: 20px auto;
  :deep(.ant-form) {
    .ant-form-item-control-input {
      min-height: 26px;
    }
    .ant-form-item-label > label {
      height: 26px;
    }
  }
}
</style>